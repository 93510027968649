<template>
  <!-- 忘记密码 -->
  <div class="user-login">
    <div class="login-form">
      <p>修改密码</p>
      <el-form class="form form2" :model="form" label-position="left" :rules="rules" ref="form2">
        <el-form-item prop="telephone">
          <el-input v-model="form.telephone" placeholder="请输入注册的用户名"></el-input>
        </el-form-item>
        <el-form-item prop="checkcode">
          <el-input style="width: 59%;" v-model="form.checkcode" placeholder="请输入短信验证码"></el-input>
          <el-button class="btn" type="primary" @click="getSmsCode" :disabled="flag">{{flag ?  `（${current}） s` : '获取验证码'}}</el-button>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="form.password" show-password minlength="8" maxlength="12" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input type="password" v-model="form.password2" show-password minlength="8" maxlength="12" placeholder="请确认新密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 20%;margin-top: 20px;" type="primary" @click="cancel">取消</el-button>
          <el-button style="width: 70%;margin-top: 20px;" type="primary" @click="submit">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {  getResetSmsCode, resetPwd } from '../../api/loginAndRegister'
import { telephoneValidator } from '../../js/utils/rules'
export default {
  data() {
    return {
      form: {},
      rules: {
        telephone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          { trigger: 'blur', validator: telephoneValidator }
        ],
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入新密码'));
              } else if (this.form.password2 && value !== this.form.password2) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            }
          }
        ],
        password2: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入新密码'));
              } else if (this.form.password && value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            }
          }
        ],
        checkcode: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'},
        ],
      },
      flag: false,
      max: 60,
      current: 60,
      decreseInterval: null
    }
  },
  created() {
  },
  methods: {
    async getSmsCode() {
      // 获取手机验证码
      if(this.form.telephone) {
        let res = await getResetSmsCode({
          phone:this.form.telephone,
          type:3
        });
        let {code, data, msg} = res;
        if(code == 0) {
          this.$message.success(msg)
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if(this.current <= this.max && this.current > 0) {
              this.current --;
              this.flag = true;
            } else if(this.current <= 0) {
              this.current = 0
              this.flag = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000)
        } else {
          this.$message.error(msg)
        }
      } else {
        this.$message.error('请先输入注册的用户名！')
      }
    },
    cancel() {
      this.$router.go(-1)
    },
    submit() {
      // 提交立即注册
      this.$refs.form2.validate(async valid => {
        if(valid) {
          let params = {
            phone: this.form.telephone,
            smsCode: this.form.checkcode,
            password: this.form.password,
            repassword: this.form.password2,
          }
          let res = await resetPwd(params);
          let {code, msg} = res;
          if(code == 0) {
            this.$message.success('修改成功，您可以登录了！');
            this.$router.push({name: 'login'})
          } else {
            this.$message.error(msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-login {
  height: 651px;
  width: 100%;
  position: relative;
  z-index: 3;
  background-image: url('../../assets/imgs/login-bg.png');
  background-position: 0 45%;
  background-repeat: no-repeat;
  padding-top: 92px;
  background-size: 100% auto;
  .login-form {
    // width: 431px;
    // height: 414px;
    width: 400px;
	  height: 434px;
    padding: 40px 53px 0 53px;
    background-color: #ffffff;
    border-radius: 10px;
    // position: absolute;
    // top: 60px;
    // right: 387px;
    // top: 92px;
    // right: 779px;
    // right: 40%;
    margin: 0px auto;
    .tabs {
      width: 100%;
      height: 42px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tab-item {
        width: 215px;
        height: 40px;
        line-height: 20px;
        margin-right: 35px;
        font-size: 18px;
      }
      .active {
        color: #00a0e9;
        border-bottom: 2px solid #00a0e9;
      }
    }
    .form {
      margin-top: 32px;
      .el-form-item {
        margin-bottom: 33px;
      }
      .btn {
        width: 147px;
        height: 40px;
        margin-left: 15px;
      }
    }
    .register {
      color: #00a0e9;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
    .form2 {
      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>

<style lang="less">
.my-height {
  .el-input__inner {
    height: 48px;
  }
}
</style>
